/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

// UI lib comoponents
import { Col, Container, Row } from 'react-grid-system';

// Local UI components
import { useI18next } from 'gatsby-plugin-react-i18next';
import FormGenerator from '../../../shared/UIKit/FormGenerator';
import Image from '../../../shared/Image';
// Redux actions and selectors
// @TODO: If using Redux, import actions and selectors

// Helpers & utils
// @TODO: Import any helper or service here

// Style
import './index.scss';
// form schema
import schema from '../../../shared/FormSchemas/becomePartnerSchema.json';
import { useCountries, useDomains } from '../../../shared/Hooks/useData';
import { API_PARTNER_REQUEST } from '../../../shared/ApIs';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function Form({ handleNext }) {
  const { language } = useI18next();
  /* ******************************** CHOOKS ******************************* */
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState();
  const { domains } = useDomains();
  const { countries } = useCountries();

  const selectData = useMemo(() => {
    return {
      country: countries.map(({ id, attributes }) => ({
        label: attributes[`name_${language}`],
        value: id,
      })),
      domain: domains.map(({ id, attributes }) => ({
        label: attributes?.name,
        value: id,
      })),
    };
  }, [domains, countries, language]);
  // localization

  const { t } = useTranslation();
  const { title, buttonValue } = t('form', { returnObjects: true });
  const { title1, title2, list, description1, listsecteur, description4 } = t(
    'expert',
    { returnObjects: true },
  );
  /* ******************************** RENDERING ******************************* */
  function ListSecteur() {
    return listsecteur.map(({ id, titlesecteur, item }) => {
      return (
        <Row key={id} justify="center">
          <Col lg={9} className="bp-form-part1-listsecteur-text">
            <span className="bp-form-part1-listsecteur-text-gras">
              {titlesecteur}
            </span>
            {item}
          </Col>
        </Row>
      );
    });
  }
  function ListItem() {
    return list.map(({ id, item, srcImg }) => {
      return (
        <Col lg={6} key={id}>
          <Row className="bp-form-part1-list" justify="center">
            <Col lg={2} xs={2}>
              <Image src={srcImg} alt="list" />
            </Col>
            <Col lg={8.5} xs={8.5} className="bp-form-part1-list-text">
              {item}
            </Col>
          </Row>
        </Col>
      );
    });
  }

  const handleSubmit = async (data) => {
    setLoading(true);
    try {
      await axios.post(API_PARTNER_REQUEST, {
        data: { ...data },
      });
      // const formData = new FormData();
      // formData.append('files', data?.cv);
      // formData.append('refId', res?.data?.data?.id);
      // formData.append('ref', 'api::expert-request.expert-request');
      // formData.append('field', 'cv');
      // await axios.post(API_UPLOAD_FILE, formData);

      setResponse('SUCCESS');
      handleNext();
    } catch (error) {
      setResponse('ERROR');
      setLoading(false);
      throw new Error('error occured');
    }
    setLoading(false);
  };

  return (
    <>
      <div className="bp-form-part1">
        <h2 className="bp-form-part1-title">{title1}</h2>
        <div className="bp-form-part1-desc">
          <span>{description1}</span>
          <ListSecteur />
          <span>{title2}</span>
        </div>
        <Row>
          <ListItem />
        </Row>
        <p className="bp-form-part1-desc">{description4}</p>
      </div>

      <div className="bp-form">
        <div className="bp-form-container">
          <Container>
            <Row>
              <Col lg={12}>
                <h1 style={{ width: '100%' }} className="title">
                  {title}
                </h1>
              </Col>
              {/* <Col>
                <button type="button" className="bp-form-linkdin">
                  <div className="bp-form-linkdin-img">
                    <Image src="/Group 22.png" alt="ggf" />
                  </div>
                  <p className="bp-form-linkdin-text">{linkValue}</p>
                </button>
              </Col> */}
            </Row>
            <FormGenerator
              schema={schema}
              buttonValue={buttonValue}
              selectData={selectData}
              loading={loading}
              onSubmit={handleSubmit}
            />
            {response && (
              <div
                className={`${response === 'ERROR' ? 'error-msg' : ''} ${
                  response === 'SUCCESS' ? 'success-msg' : ''
                } align-c`}
              >
                {response === 'SUCCESS'
                  ? 'Votre demande a été enregistrée avec sucess'
                  : ''}
                {response === 'ERROR'
                  ? "un message d'erreur s'est produit"
                  : ''}
              </div>
            )}
          </Container>
        </div>
      </div>
    </>
  );
}

Form.propTypes = {
  handleNext: PropTypes.shape().isRequired,
};

Form.defaultProps = {};

export default Form;
