import axios from 'axios';
import { API_SARL, API_SARL_BY_USER, baseUrl } from '.';

export const prepareSarlData = (formData) => {
  const user = localStorage.getItem('user');
  const parsedUser = JSON.parse(user);
  return {
    // owner: formData.owner,
    country: formData?.country,
    category: formData?.category,
    activity_sector: formData?.field?.value,
    partners: formData?.partners?.map((partner) => ({
      lastName: partner?.lastName,
      firstName: partner?.firstName,
      target_country: partner?.nationality,
      capital: partner?.capital,
    })),
    type: formData?.type,
    location: formData?.location,
    users_permissions_user: parsedUser?.id,
  };
};

export const createSARL = async (formData) => {
  try {
    const { data } = await axios.post(API_SARL(), {
      data: prepareSarlData(formData),
    });
    localStorage.setItem('sarl', JSON.stringify(data.data));
  } catch (error) {
    console.log(error);
  }
};

export const updateSARL = async (id, formData) => {
  try {
    const { data } = await axios.put(API_SARL(id), {
      data: prepareSarlData(formData),
    });
    localStorage.setItem('sarl', JSON.stringify(data.data));
  } catch (error) {
    console.log(error);
  }
};

export const transformSARL = (data, language) => {
  // const owner = {
  //   firstName: data?.attributes?.owner?.firstName ?? '',
  //   lastName: data?.attributes?.owner?.lastName ?? '',
  //   email: data?.attributes?.owner?.email ?? '',
  //   phoneNumber: data?.attributes?.owner?.phoneNumber ?? '',
  // };
  const user = localStorage.getItem('user');
  const partners = data?.attributes?.partners?.map((partner) => ({
    firstName: partner?.firstName,
    lastName: partner?.lastName,
    nationality: partner?.target_country?.data?.id,
    capital: partner?.capital,
  }));

  return {
    id: data?.id,
    owner: user ? JSON.parse(user) : {},
    country: data?.attributes?.country?.data?.id,
    category: data?.attributes?.category?.data?.id,
    type: data?.attributes?.type?.data?.id,
    location: data?.attributes?.location,
    field: {
      value: data?.attributes?.activity_sector?.data?.id,
      label:
        data?.attributes?.activity_sector?.data?.attributes[
          `name_${language ?? 'fr'}`
        ],
    },
    partners:
      partners?.length > 0
        ? partners
        : [{ firstName: '', lastName: '', nationality: null, capital: null }],
  };
};

export const getSarl = async (userId) => {
  try {
    let response = null;
    const { data: currSarl } = await axios.get(API_SARL_BY_USER(userId));

    if (currSarl?.data) {
      response = currSarl?.data[0];
    } else {
      const { data } = await axios.post(API_SARL(), {
        data: {},
      });
      response = data?.data;
    }
    localStorage.setItem('sarl', JSON.stringify(response));
    return response;
  } catch (error) {
    return null;
  }
};

export const emailUsed = async (email) => {
  try {
    const data = await axios.get(
      `${baseUrl}/users?filters[email][$eq]=${email.toLowerCase()}`,
    );

    if (data?.data?.length > 0) {
      return true;
    }

    return false;
  } catch (err) {
    return false;
  }
};
