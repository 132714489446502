/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';

// Lib UI components

// Local UI components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import Form from '../page-components/BecomePartner/Form';

// Style
import '../page-styles/BecomePartner.scss';
import PartnerRegistered from '../page-components/BecomePartner/PartnerRegistered';
import Questions from '../page-components/BecomePartner/Questions';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function BecomePartner() {
  /* ********************* HOOKS ********************************* */
  const [steps, setSteps] = React.useState(0);

  const handleNext = () => {
    setSteps((prev) => prev + 1);
  };

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Become partner" />
      <div className="become-partner">
        {steps === 0 && <Form handleNext={handleNext} />}
        {steps === 1 && <PartnerRegistered handleNext={handleNext} />}
        {steps === 2 && <Questions />}
      </div>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["BecomePartner", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default BecomePartner;
