/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// Lib UI components

// Local UI components

import swiftLogo from '../../images/swift-logo.svg';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function PartnerRegistered({ handleNext }) {
  /* ********************* HOOKS ********************************* */
  const { t } = useTranslation();
  const { title, message } = t('partnerRegistered', { returnObjects: true });

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleNext();
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);
  /* ******************************** RENDERING ******************************* */
  return (
    <div className="partner-registered">
      <div className="success-card">
        <img src={swiftLogo} alt="" />
        <p className="title">{title}</p>
        <p className="message">{message}</p>
      </div>
    </div>
  );
}

PartnerRegistered.propTypes = {
  handleNext: PropTypes.shape().isRequired,
};

export default PartnerRegistered;
