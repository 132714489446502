import axios from 'axios';
import { useEffect, useState } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import {
  API_CATEGORIES,
  API_COUNTRIES,
  API_DOMAINS,
  API_FUNCTIONS,
  API_NATIONALITIES,
  API_REGISTER,
  API_SECTORS,
  API_SOURCE_COUNTRIES,
  API_TARGET_COUNTRIES,
  API_TYPES,
  API_USER,
} from '../ApIs';
import { transformSARL } from '../ApIs/sarl';

export function useCountries() {
  const [countries, setCountries] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    (async function getCountries() {
      try {
        const { data } = await axios.get(API_COUNTRIES);
        setCountries(data?.data);
      } catch (err) {
        setError(err);
      }
    })();
  }, []);

  return { countries, error };
}

export function useDomains(category) {
  const [domains, setDomains] = useState([]);
  const [error, setError] = useState();
  const { language } = useI18next();

  useEffect(() => {
    (async function getDomains() {
      try {
        const { data } = await axios.get(API_DOMAINS(category, language));
        setDomains(data?.data);
      } catch (err) {
        setError(err);
      }
    })();
  }, [category, language]);

  return { domains, error };
}

export function useFunctions() {
  const [functions, setFunctions] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    (async function getFunctions() {
      try {
        const { data } = await axios.get(API_FUNCTIONS);
        setFunctions(data?.data);
      } catch (err) {
        setError(err);
      }
    })();
  }, []);

  return { functions, error };
}

export function useTargetCountries() {
  const { language } = useI18next();
  const [countries, setCountries] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    (async function getCountries() {
      try {
        const { data } = await axios.get(API_TARGET_COUNTRIES);
        setCountries(
          data?.data?.map((item) => ({
            value: item?.id,
            label:
              item?.attributes?.country?.data?.attributes[`name_${language}`],
            flag: item?.attributes?.country?.data?.attributes?.flag?.data[0]
              ?.attributes?.url,
          })),
        );
      } catch (err) {
        setError(err);
      }
    })();
  }, [language]);

  return { countries, error };
}

export function useSourceCountries() {
  const { language } = useI18next();
  const [countries, setCountries] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    (async function getCountries() {
      try {
        const { data } = await axios.get(API_SOURCE_COUNTRIES);
        setCountries(
          data?.data?.map((item) => ({
            value: item?.id,
            label:
              item?.attributes?.country?.data?.attributes[`name_${language}`],
            flag: item?.attributes?.country?.data?.attributes?.flag?.data[0]
              ?.attributes?.url,
          })),
        );
      } catch (err) {
        setError(err);
      }
    })();
  }, [language]);

  return { countries, error };
}

export function useCategories() {
  const { language } = useI18next();
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function getCategories() {
      try {
        setLoading(true);
        const { data } = await axios.get(API_CATEGORIES());
        setCategories(
          data?.data?.map((item) => {
            return {
              value: item?.id,
              label: item?.attributes[`name_${language ?? 'fr'}`],
              icon: item?.attributes?.icon?.data?.attributes?.url,
            };
          }),
        );
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [language]);

  return { categories, loading, error };
}

export function useSectors() {
  const { language } = useI18next();
  const [sectors, setSectors] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const handleSearch = (category) => {
    return async (name = '') => {
      try {
        setLoading(true);
        const { data } = await axios.get(API_SECTORS(name, language, category));

        setSectors(
          data?.data?.map((item) => ({
            value: item?.id,
            label: item?.attributes[`name_${language ?? 'fr'}`],
          })),
        );
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
  };

  return { sectors, error, loading, handleSearch };
}

export function useCompanyTypes() {
  const { language } = useI18next();
  const [companyTypes, setCompanyTypes] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function getCompanyTypes() {
      try {
        setLoading(true);
        const { data } = await axios.get(API_TYPES());
        setCompanyTypes(
          data?.data?.map((item) => ({
            value: item?.id,
            label: item?.attributes[`name_${language ?? 'fr'}`],
            description: item?.attributes[`description_${language ?? 'fr'}`],
            icon: item?.attributes?.icon?.data?.attributes?.url,
            video: item?.attributes?.video?.data?.attributes?.url,
          })),
        );
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [language]);

  return { companyTypes, loading, error };
}

export function useUser() {
  const [user, setUser] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  async function register(formData, callback = () => {}) {
    try {
      setLoading(true);
      const savedSarl = localStorage.getItem('sarl');
      const sarl = transformSARL(JSON.parse(savedSarl));
      const { data } = await axios.post(API_REGISTER, {
        username: formData?.email,
        ...formData,
        sarl: sarl?.id,
      });
      localStorage.setItem('token', `Bearer ${data.jwt}`);
      localStorage.setItem(
        'user',
        JSON.stringify({
          id: data?.user?.id,
          firstName: data?.user?.firstName,
          lastName: data?.user?.lastName,
          email: data?.user?.email,
          phoneNumber: data?.user?.phoneNumber,
        }),
      );
      setUser(data?.user);
      callback();
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  async function updateUser(formData, callback = () => {}) {
    try {
      const token = localStorage.getItem('token');
      const currUser = localStorage.getItem('user');
      const parsedUser = JSON.parse(currUser);
      setLoading(true);
      const { data } = await axios.put(API_USER(parsedUser?.id), formData, {
        headers: {
          Authorization: token,
        },
      });
      localStorage.setItem(
        'user',
        JSON.stringify({
          id: data?.id,
          firstName: data?.firstName,
          lastName: data?.lastName,
          email: data?.email,
          phoneNumber: data?.phoneNumber,
        }),
      );
      callback();
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  return { user, register, updateUser, loading, error };
}

export function useNationalities() {
  const { language } = useI18next();
  const [nationalities, setNationalities] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    (async function getNationalities() {
      try {
        const { data } = await axios.get(API_NATIONALITIES);
        setNationalities(
          data?.data?.map((item) => ({
            value: item?.id,
            label: item?.attributes[`name_${language}`],
          })),
        );
      } catch (err) {
        setError(err);
      }
    })();
  }, [language]);

  return { nationalities, error };
}

export function useAllCountries() {
  const { language } = useI18next();
  const [allCountries, setAllCountries] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    (async function getCountries() {
      try {
        const { data } = await axios.get(API_COUNTRIES);
        setAllCountries(
          data?.data?.map((item) => ({
            value: item?.id,
            label: item?.attributes?.[`name_${language}`],
            flag: item?.attributes?.flag?.data[0]?.attributes?.url,
          })),
        );
      } catch (err) {
        setError(err);
      }
    })();
  }, [language]);

  return { allCountries, error };
}
