/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Lib UI components
import { Container } from 'react-grid-system';

// Local UI components

import handShake from '../../images/handshake.svg';
// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function Questions() {
  /* ********************* HOOKS ********************************* */
  const [questionIndex, setQuestionIndex] = useState(0);
  const [value, setValue] = useState({});
  const { t } = useTranslation();
  const { message, continueButton, prevButton, questions, completedMessage } =
    t('partnerQuestions', { returnObjects: true });

  const handleChange = (e, index) => {
    setValue((prev) => {
      return {
        ...prev,
        [index]: e.target.value,
      };
    });
  };

  /* ******************************** RENDERING ******************************* */
  return (
    <div className="partner-questions">
      <div className="left-side">
        <Container fluid>
          <img src={handShake} alt="" className="mb-30" />
          <p className="title mb-20">{message.title}</p>
          <p className="description mb-20">{message.description}</p>
        </Container>
      </div>

      <div className="right-side">
        {questionIndex < 5 && questions[questionIndex] ? (
          <>
            <div className="question-indicator">
              <p className="question-name">
                Question
                {questionIndex + 1}
              </p>
              <div className="question-steps">
                {Array(5)
                  .fill()
                  .map((_, index) => (
                    <div
                      className={`${index <= questionIndex ? 'active' : ''}`}
                    />
                  ))}
              </div>
            </div>
            <div className="question-form mt-30">
              <p className="question">{questions[questionIndex].name}</p>
              <textarea
                id=""
                cols="30"
                rows="14"
                className="mt-40 w-100"
                value={value[questionIndex] ?? ''}
                onChange={(e) => handleChange(e, questionIndex)}
              />
              <div className="w-100 button-container mt-40">
                {questionIndex > 0 && (
                  <button
                    type="button"
                    className="prev-button"
                    onClick={() => setQuestionIndex((prev) => prev - 1)}
                  >
                    {prevButton}
                  </button>
                )}
                <button
                  type="button"
                  className="base-button primary-button"
                  onClick={() => {
                    setQuestionIndex((prev) => prev + 1);
                  }}
                >
                  {continueButton}
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="questions-complete">
            <p className="title mb-20">{completedMessage.title}</p>

            <p className="description mb-20">{completedMessage.description}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Questions;
